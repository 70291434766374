
import { defineComponent, ref } from "vue";
import { Field, Form } from "vee-validate";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
    name: "change-password",
    components: {
        Field,
        Form,
    },
    data() {
        return {
            passwordConfirmed: "",
            password: "",
            has_number: false,
            has_lowercase: false,
            has_uppercase: false,
            has_special: false,
            passwordMatch: false,
            passwordLength: false,
            passwordType: "password",
            passwordConfirmType: "password",
            activeEye: false,
            activeConfirmEye: false,
            enabledbutton: false,
        };
    },
    methods: {
        show_password: function () {
            this.passwordType = this.passwordType === "password" ? "text" : "password";
            this.activeEye = !this.activeEye;
        },
        show_confirmed_password: function () {
            this.passwordConfirmType = this.passwordConfirmType === "password" ? "text" : "password";
            this.activeConfirmEye = !this.activeConfirmEye;
        },
        checkPassword: function (e) {
            if (/\d/.test(this.password)) this.has_number = true;
            else this.has_number = false;

            if (/[a-z]/.test(this.password)) this.has_lowercase = true;
            else this.has_lowercase = false;

            if (/[A-Z]/.test(this.password)) this.has_uppercase = true;
            else this.has_uppercase = false;

            if (/[!@#$%^&*)(+=._-]/.test(this.password)) this.has_special = true;
            else this.has_special = false;

            if (this.password === this.passwordConfirmed && this.password !== "" && this.passwordConfirmed !== "") this.passwordMatch = true;
            else this.passwordMatch = false;

            if (this.password.length >= 8 || this.passwordConfirmed.length >= 8) this.passwordLength = true;
            else this.passwordLength = false;

            if (this.has_number && this.has_lowercase && this.has_uppercase && this.has_special && this.passwordMatch && this.passwordLength) this.enabledbutton = true;
            else this.enabledbutton = false;
        },
    },
    setup() {
        const store = useStore();
        const router = useRouter();

        const submitButton = ref<HTMLElement | null>(null);

        //Create form validation object
        const changePassword = Yup.object().shape({
            password: Yup.string().required().min(4),
        });

        //Form submit function
        const onSubmitChangePassword = (values) => {
            // Activate loading indicator
            submitButton.value?.setAttribute("data-kt-indicator", "on");

            // dummy delay
            setTimeout(() => {
                store
                    .dispatch(Actions.CHANGE_PASSWORD, {
                        password: values.password,
                        token: router.currentRoute.value.params.token,
                        conditions: false,
                    })
                    .then(() => {
                        Swal.fire({
                            text: "Your password has been successfully reset.",
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: "Se connecter",
                            customClass: {
                                confirmButton: "btn fw-bold btn-light-primary",
                            },
                        }).then(function () {
                            // Go to page after successfully login
                            router.push({ name: "sign-in" });
                        });
                    })
                    .catch((error) => {
                        console.log(store.getters.getErrors);
                        // Alert then login failed
                        Swal.fire({
                            text: store.getters.getErrors.global,
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: "Try again!",
                            customClass: {
                                confirmButton: "btn fw-bold btn-light-danger",
                            },
                        });
                    });
                submitButton.value?.removeAttribute("data-kt-indicator");
            }, 2000);
        };

        return {
            onSubmitChangePassword,
            changePassword,
            submitButton,
        };
    },
});
