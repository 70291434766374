<template>
    <!--begin::Wrapper-->
    <div class="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
        <!--begin::Form-->
        <Form class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" @submit="onSubmitChangePassword" id="kt_change_password_form" :validation-schema="changePassword">
            <!--begin::Heading-->
            <div class="text-center mb-10">
                <!--begin::Title-->
                <h1 class="text-dark mb-3">Reset Password</h1>
                <!--end::Title-->

                <!--begin::Link-->
                <div class="text-gray-400 fw-bold fs-4">Please enter your new password.</div>
                <!--end::Link-->
            </div>
            <!--begin::Heading-->

            <!--begin::Input group-->
            <div class="fv-row mb-10">
                <label class="form-label fw-bolder text-gray-900 fs-6">New Password</label>
                <Field
                    class="form-control form-control-solid"
                    :type="passwordType"
                    placeholder=""
                    name="password"
                    autocomplete="off"
                    @keyup="checkPassword"
                    @change="checkPassword"
                    v-model="password"
                />
                <i @click="show_password" class="far fa-eye password-eye" v-bind:class="{ 'text-primary': activeEye }"></i>
            </div>
              <div class="password-checker mb-10 p-3 ps-5">
                <p class="pc-line mb-1" v-bind:class="{ 'text-primary fw-bold': has_lowercase, 'text-gray-400': !has_lowercase }">
                    <i class="fas" v-bind:class="{ 'text-primary fa-check': has_lowercase, 'text-gray-400 fa-times': !has_lowercase }"></i> A lowercase letter
                </p>
                <p class="pc-line mb-1" v-bind:class="{ 'text-primary fw-bold': has_uppercase, 'text-gray-400': !has_uppercase }">
                    <i class="fas" v-bind:class="{ 'text-primary fa-check': has_uppercase, 'text-gray-400 fa-times': !has_uppercase }"></i> An uppercase letter
                </p>
                <p class="pc-line mb-1" v-bind:class="{ 'text-primary fw-bold': has_number, 'text-gray-400': !has_number }">
                    <i class="fas" v-bind:class="{ 'text-primary fa-check': has_number, 'text-gray-400 fa-times': !has_number }"></i> A number
                </p>
                <p class="pc-line mb-1" v-bind:class="{ 'text-primary fw-bold': has_special, 'text-gray-400': !has_special }">
                    <i class="fas" v-bind:class="{ 'text-primary fa-check': has_special, 'text-gray-400 fa-times': !has_special }"></i> A special character
                </p>
                <p class="pc-line mb-1" v-bind:class="{ 'text-primary fw-bold': passwordLength, 'text-gray-400': !passwordLength }">
                    <i class="fas" v-bind:class="{ 'text-primary fa-check': passwordLength, 'text-gray-400 fa-times': !passwordLength }"></i> Minimum 8 characters


                </p>
                <p class="pc-line mb-1" v-bind:class="{ 'text-primary fw-bold': passwordMatch, 'text-gray-400': !passwordMatch }">
                    <i class="fas" v-bind:class="{ 'text-primary fa-check': passwordMatch, 'text-gray-400 fa-times': !passwordMatch }"></i> Passwords match


                </p>
            </div>
            <div class="fv-row mb-10">
                <label class="form-label fw-bolder text-gray-900 fs-6">Confirm your password</label>
                <Field
                    class="form-control form-control-solid"
                    :type="passwordConfirmType"
                    placeholder=""
                    name="confirmPassword"
                    autocomplete="off"
                    @keyup="checkPassword"
                    @change="checkPassword"
                    v-model="passwordConfirmed"
                />
                <i @click="show_confirmed_password" class="far fa-eye password-eye" v-bind:class="{ 'text-primary': activeConfirmEye }"></i>
            </div>
            <!--end::Input group-->



            <!--begin::Actions-->
            <div class="d-flex flex-wrap justify-content-center pb-lg-0">
                <button v-if="enabledbutton" type="submit" ref="submitButton" id="kt_change_password_submit" class="btn btn-lg btn-primary fw-bolder me-4">
                    <span class="indicator-label"> Reset Password </span>
                    <span class="indicator-progress">
                        Please wait...
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                </button>
                <button v-if="!enabledbutton" disabled type="submit" ref="submitButton" id="kt_change_password_submit" class="btn btn-lg btn-primary fw-bolder me-4">
                    <span class="indicator-label"> Reset Password </span>
                </button>
            </div>
            <!--end::Actions-->
        </Form>
        <!--end::Form-->
    </div>
    <!--end::Wrapper-->
</template>
<style>
.password-eye {
    float: right;
    margin-right: 20px;
    margin-top: -26px;
    position: relative;
    cursor: pointer;
    transition: all ease .25s;
}

.password-checker {
    border: 1px dashed #009ef7;
    border-radius: 7px;
    background-color: #f1faff;
}

.pc-line {
    display: flex;
    align-items: center;
}

.pc-line i {
    margin-right: 5px;
}
</style>
<script lang="ts">
import { defineComponent, ref } from "vue";
import { Field, Form } from "vee-validate";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
    name: "change-password",
    components: {
        Field,
        Form,
    },
    data() {
        return {
            passwordConfirmed: "",
            password: "",
            has_number: false,
            has_lowercase: false,
            has_uppercase: false,
            has_special: false,
            passwordMatch: false,
            passwordLength: false,
            passwordType: "password",
            passwordConfirmType: "password",
            activeEye: false,
            activeConfirmEye: false,
            enabledbutton: false,
        };
    },
    methods: {
        show_password: function () {
            this.passwordType = this.passwordType === "password" ? "text" : "password";
            this.activeEye = !this.activeEye;
        },
        show_confirmed_password: function () {
            this.passwordConfirmType = this.passwordConfirmType === "password" ? "text" : "password";
            this.activeConfirmEye = !this.activeConfirmEye;
        },
        checkPassword: function (e) {
            if (/\d/.test(this.password)) this.has_number = true;
            else this.has_number = false;

            if (/[a-z]/.test(this.password)) this.has_lowercase = true;
            else this.has_lowercase = false;

            if (/[A-Z]/.test(this.password)) this.has_uppercase = true;
            else this.has_uppercase = false;

            if (/[!@#$%^&*)(+=._-]/.test(this.password)) this.has_special = true;
            else this.has_special = false;

            if (this.password === this.passwordConfirmed && this.password !== "" && this.passwordConfirmed !== "") this.passwordMatch = true;
            else this.passwordMatch = false;

            if (this.password.length >= 8 || this.passwordConfirmed.length >= 8) this.passwordLength = true;
            else this.passwordLength = false;

            if (this.has_number && this.has_lowercase && this.has_uppercase && this.has_special && this.passwordMatch && this.passwordLength) this.enabledbutton = true;
            else this.enabledbutton = false;
        },
    },
    setup() {
        const store = useStore();
        const router = useRouter();

        const submitButton = ref<HTMLElement | null>(null);

        //Create form validation object
        const changePassword = Yup.object().shape({
            password: Yup.string().required().min(4),
        });

        //Form submit function
        const onSubmitChangePassword = (values) => {
            // Activate loading indicator
            submitButton.value?.setAttribute("data-kt-indicator", "on");

            // dummy delay
            setTimeout(() => {
                store
                    .dispatch(Actions.CHANGE_PASSWORD, {
                        password: values.password,
                        token: router.currentRoute.value.params.token,
                        conditions: false,
                    })
                    .then(() => {
                        Swal.fire({
                            text: "Your password has been successfully reset.",
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: "Se connecter",
                            customClass: {
                                confirmButton: "btn fw-bold btn-light-primary",
                            },
                        }).then(function () {
                            // Go to page after successfully login
                            router.push({ name: "sign-in" });
                        });
                    })
                    .catch((error) => {
                        console.log(store.getters.getErrors);
                        // Alert then login failed
                        Swal.fire({
                            text: store.getters.getErrors.global,
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: "Try again!",
                            customClass: {
                                confirmButton: "btn fw-bold btn-light-danger",
                            },
                        });
                    });
                submitButton.value?.removeAttribute("data-kt-indicator");
            }, 2000);
        };

        return {
            onSubmitChangePassword,
            changePassword,
            submitButton,
        };
    },
});
</script>
